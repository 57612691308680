.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.data-table-wrapper {
  width: 100%
}

table.dataTable {
  border-collapse: separate;
  empty-cells: hide;
}


[data-amplify-authenticator] {
  --amplify-components-button-primary-background-color: #0071FF;
  --amplify-components-button-primary-hover-background-color: #0071FF;
  --amplify-components-tabs-item-active-border-color: #0071FF;
  --amplify-components-image-height: 50px;
  --amplify-components-fieldcontrol-border-color: #ccc;
  --amplify-components-textfield-border-color: #ccc;
  --amplify-components-button-border-color: #ccc;
  --amplify-components-authenticator-router-border-color: #f1f1f1;
  --amplify-components-authenticator-modal-background-color: "white"
}